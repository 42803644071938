import type { BaseData, IMainSubStructureModel, NormalProgrammeParams } from '@/utils';
import { RenderByPermission, SearchListModal, copyToClipboard, getWxUrl, request } from '@/utils';
import { Modal, message } from 'antd';
import { action, observable } from 'mobx';
import { API } from '../api';
import { ChangeHousekeeperModel } from './changebutleModal/model';
import FeedBackModel from './feedBackModal/model';

const { confirm } = Modal;

export default class Model {
  // 反馈意愿弹窗
  public feedBackStore = new FeedBackModel(this);
  public changeHousekeeperStore = new ChangeHousekeeperModel(this);

  constructor() {
    this.mainStore.grid.onQuery();
  }

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        field: 'companyName',
        label: '公司名称',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'bdName',
        label: '绑定BD',
        placeholder: '请输入',
      },
      {
        type: 'select',
        field: 'intention',
        label: '意愿',
        data: [
          {
            label: '待联系',
            value: '0',
          },
          {
            label: '有意愿',
            value: '1',
          },
          {
            label: '暂无意愿',
            value: '2',
          },
        ],
      },
      {
        type: 'input',
        field: 'butlerName',
        label: '招聘管家',
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    buttons: [
      {
        text: '批量更换管家',
        handleClick: () => {
          const selectedIds = this.mainStore.grid.gridModel.selectedIds;
          if (Array.from(selectedIds).length === 0) {
            message.warning('请选择需要更换管家的主播');
            return;
          }
          const userIdList: string[] = Array.from(selectedIds) as any;
          this.changeHousekeeperStore.onShow(userIdList);
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'opreations',
          name: '操作',
          frozen: true,
          width: 200,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_64`}>
                  <a
                    key="detail"
                    className="px-1"
                    onClick={() => {
                      this.feedBackStore.onOpenFeedBackModal(row.intentionId);
                    }}
                    type="link"
                  >
                    反馈意愿
                  </a>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_65`}>
                  <a
                    key="relevance"
                    className="px-1"
                    onClick={() => {
                      getWxUrl({
                        channel: 21,
                        employerId: row.id,
                      }).then((url) => {
                        copyToClipboard(url);
                        message.success('复制链接成功');
                      });
                    }}
                    type="link"
                  >
                    绑定账号
                  </a>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'name',
          name: '雇主昵称',
        },
        {
          key: 'contactMobile',
          name: '联系手机号',
        },
        {
          key: 'wxCode',
          name: '联系微信号',
        },
        {
          key: 'companyName',
          name: '公司名称',
        },
        {
          key: 'bdName',
          name: '绑定BD',
        },
        {
          key: 'butlerName',
          name: '招聘管家',
        },
        {
          key: 'intention',
          name: '意愿',
          formatter: ({ row }) => {
            return row.intention === 1 ? '有意愿' : row.intention === 2 ? '暂无意愿' : '待联系';
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params: Record<string, any>) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };

        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        if (postParams.createTime) {
          postParams.createTimeBegin = postParams.createTime.split(',')[0];
          postParams.createTimeEnd = postParams.createTime.split(',')[1];
          // biome-ignore lint/performance/noDelete: <explanation>
          delete postParams.createTime;
        }
        return request<BaseData<any>>({
          url: API.virtualEmployer,
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  };

  public mainStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });
  // 反馈意愿
  @action public onFeedback = async (id?: string | number) => {
    confirm({
      content: '是否批量反馈客户意愿？',
      onOk: async () => {
        const res = await request<BaseData>({
          url: API.feedback,
          method: 'POST',
          data: {
            positionIdList: Array.from(this.mainStore.grid.gridModel.selectedIds),
          },
        });
      },
    });
  };
  @action public bindCount = async (id?: string | number) => {
    confirm({
      content: '确定绑定该雇主账号吗？',
      onOk: async () => {
        const res = await request<BaseData>({
          url: API.confirmRelevancy,
          method: 'POST',
          data: {
            id,
          },
        });
      },
    });
  };
}
