import { type BaseData, MainSubStructureModel, request } from '@/utils';
import { Checkbox, Radio, Tag, Tooltip, message } from 'antd';
import { action, observable } from 'mobx';
import { API } from '../../api';

export class ChangeHousekeeperModel {
  @observable public parent: any;
  @observable public visible = false;
  @observable public ids;
  @observable public data: any;
  constructor(parent: any) {
    this.parent = parent;
  }

  @action onShow = (ids) => {
    this.visible = true;
    this.ids = ids;
    this.mainSubStructureModel.onQuery();
  };

  @action onClose = () => {
    this.visible = false;
  };

  @action onSave = () => {
    const selectId = Array.from(this.mainSubStructureModel.gridModel.selectedIds).join('');

    if (!selectId) {
      message.warning('请选择想更换的管家');
    } else {
      request<BaseData<any>>({
        url: API.modifyButler,
        method: 'POST',
        data: {
          employerIdList: this.ids,
          butlerId: selectId,
        },
      }).then((resp) => {
        if (resp.data) {
          this.onClose();
          message.success('修改成功');
          this.parent.mainStore.grid.gridModel.onRefresh();
        }
      });
    }
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    pageId: 'serviceRecord',
    grid: {
      columns: [
        {
          key: 'avatar',
          name: '',
          width: 140,
          formatter: ({ row }) => {
            const { avatar } = row;

            if (avatar) {
              return (
                <img
                  src={avatar}
                  alt="管家照片"
                  style={{ height: 80, width: 80, objectFit: 'cover', marginTop: 15 }}
                />
              );
            }
            return '';
          },
        },
        {
          key: 'info',
          name: '',
          formatter: ({ row }) => {
            const { cityList, name } = row;
            const tags = cityList?.map((v: { id: number; name: string }) => (
              <Tag
                key={v.id}
                className="mr-1 mt-1"
              >
                {v.name}
              </Tag>
            ));

            const allTagsText = cityList?.map((v: { id: number; name: string }) => v.name).join(', ');

            return (
              <div className="flex flex-col mt-1">
                <div className="h-5 leading-18">{name}</div>
                <div className="h-5 leading-18 text-sm mt-1">服务城市：</div>
                <div className="mt-1">
                  <Tooltip title={allTagsText}>
                    <div className="w-500 flex flex-wrap whitespace-normal overflow-hidden text-ellipsis line-height-base max-h-13">
                      {tags}
                    </div>
                  </Tooltip>
                </div>
              </div>
            );
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rowHeight: 110,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: false,
      showSelectedTotal: false,
    },

    hiddenSubTable: true,
    api: {
      onQuery: async (params) => {
        const { ...rest } = params;
        const postParams = {
          ...rest,
        };
        return request<BaseData<any>>({
          url: API.butlerList,
          method: 'POST',
          data: {
            ...postParams,
          },
        });
      },
      onRowSelectChange: (ids) => {
        if (ids.size > 1) {
          // 获取最新的元素
          const latestId = Array.from(ids).pop();
          ids.clear();
          if (latestId !== undefined) {
            ids.add(latestId);
          }
          this.mainSubStructureModel.gridModel.selectedIds = ids;
        }
      },
    },
  });
}
